import React, { useContext } from 'react';
import { appContext } from '@utils';
import { observer } from 'mobx-react';
import { SettingsContainer } from '@components';
import { InsuranceDrawer } from './InsuranceDrawer';

export const InsuranceSettings = observer(() => {
  const [isInsuranceOpen, setIsInsuranceOpen] = React.useState(false);
  const [isLifeInsurance, setIsLifeInsurance] = React.useState(false);

  const {
    userStore: { settings },
  } = useContext(appContext);

  const sections = [
    {
      title: 'Life Insurance',
      onEditClick: () => {
        setIsLifeInsurance(true);
        setIsInsuranceOpen(true);
      },
      items: [
        {
          key: 'Children Benefits',
          value: `${settings?.insurance?.life?.childrensBenefits}`,
        },
        {
          key: 'SB Under 65',
          value: `${settings?.insurance?.life?.survivorBenefitsUnder65}`,
        },
        {
          key: 'SB Above 65',
          value: `${settings?.insurance?.life?.survivorBenefitsAbove65}`,
        },
        {
          key: 'Income Percent To Replace',
          value: `${settings?.insurance?.life?.incomePercentToReplace}%`,
        },
        {
          key: 'Inflation Rate',
          value: `${settings?.insurance?.life?.inflationRate}%`,
        },
        {
          key: 'Funeral Expenses',
          value: `${settings?.insurance?.life?.funeralExpenses}`,
        },
        {
          key: 'CPP Payout',
          value: `${settings?.insurance?.life?.canadaPensionPlanPayout}`,
        },
      ],
    },
    {
      title: 'Disability Insurance',
      onEditClick: () => {
        setIsLifeInsurance(false);
        setIsInsuranceOpen(true);
      },
      items: [
        {
          key: 'Income Coverage',
          value: `${settings?.insurance?.disability?.incomeCoveragePercent}%`,
        },
      ],
    },
  ];

  return (
    <>
      <SettingsContainer title="Insurance" sections={sections} />
      <InsuranceDrawer
        open={isInsuranceOpen}
        handleClose={() => setIsInsuranceOpen(false)}
        settings={settings}
        isLifeInsurance={isLifeInsurance}
      />
    </>
  );
});
