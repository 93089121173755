/* eslint-disable react/react-in-jsx-scope */
import { ConfirmationModal, CustomButton, CustomDrawer } from '@components';
import {
  Advisor,
  ALL_ORGANIZATIONS_QUERY,
  ClientOrganizationInput as BaseClientOrganizationInput,
  ClientOrganization,
  OrganizationStatus,
} from '@graphql';
import AddIcon from '@mui/icons-material/Add';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import BorderColorOutlined from '@mui/icons-material/BorderColorOutlined';
import {
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
  Checkbox,
  OutlinedInput,
} from '@mui/material';
import { appContext, sortByProperty } from '@utils';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import React, { useContext, useEffect, useMemo } from 'react';
import { COLORS } from '@styles';
import { ReactComponent as DashedDivider } from '@assets/svg/DashedDivider.svg';
import { ReactComponent as DeleteOutlined } from '@assets/svg/DeleteOutlined.svg';
import * as Yup from 'yup';
import {
  organizationStatusOptions,
  platformFeaturesLabels,
  CreateOrganizationSteps,
  PlatformFeatures,
  OrganizationUpdateEnum,
  isOrgTCRFeatureFlagActive,
} from '../../utils';

const makeStyles = () => ({
  formWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '85vh',
  } as React.CSSProperties,
  editHeader: {
    paddingBottom: '1rem',
    borderBottom: `1px dashed ${COLORS.NEUTRAL_LIGHT_3}`,
  },
  dashedDivider: {
    width: '100%',
  },
  formContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    padding: '0 1rem',
  } as React.CSSProperties,
  headerOrgId: {
    color: COLORS.NEUTRAL_LIGHT_0,
  },
  formItems: {
    margin: '1rem 0',
    borderRadius: '1rem',
  },
  inputBorder: {
    borderRadius: '1rem',
  },
  disabledMenuItem: {
    pointerEvents: 'auto !important',
    cursor: 'not-allowed !important',
  },
  checkbox: {
    '&.Mui-checked': {
      color: COLORS.ELEKTRAFI_NEUTRAL,
    },
  },
  checkboxLabel: {
    margin: '0.5rem 0 0 1rem',
  },
  section: {
    margin: '1.5rem 0 0.5rem 0',
  },
  button: {
    padding: '1.5rem 1rem 0 1rem',
    borderTop: `1px solid ${COLORS.NEUTRAL_DARK_2}`,
    marginTop: 'auto',
  },
  fullsizeSpan: { width: '100%' },
});

interface IOrganizationDrawerProps {
  open: boolean;
  onClose: () => void;
  isEdit?: boolean;
  org?: ClientOrganization;
  advisors?: Advisor[];
}

type ClientOrganizationInput = BaseClientOrganizationInput & {
  id: string;
  [PlatformFeatures.FINANCIAL_WELLNESS]: boolean;
  [PlatformFeatures.TCR]: boolean;
};

export const OrganizationDrawer = observer(
  ({ open, onClose, isEdit = false, org, advisors }: IOrganizationDrawerProps) => {
    const sx = makeStyles();
    const [createOrganizationStep, setCreateOrganizationStep] = React.useState(
      CreateOrganizationSteps.ORGANIZATION_DETAILS,
    );

    const [modalUpdateType, setModalUpdateType] = React.useState(OrganizationUpdateEnum.UPDATE);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = React.useState(false);

    const {
      organizationStore: {
        createClientOrg,
        updateOrganization,
        orgIdsByFeatureFlagConfig,
        getOrgIdsByFeatureFlagConfig,
      },
      managersStore: { managers },
      userStore: { getCurrentSession },
    } = useContext(appContext);

    const isTCRActive = useMemo(
      () => isOrgTCRFeatureFlagActive(org?.id || '', orgIdsByFeatureFlagConfig),
      [orgIdsByFeatureFlagConfig, org],
    );

    const initialValues = useMemo<ClientOrganizationInput>(
      () =>
        ({
          id: org?.id || '',
          name: org?.name || '',
          status: org?.status || OrganizationStatus.Acitve,
          managerId: org?.admin?.id || '',
          advisorId: org?.advisor.id || '',
          [PlatformFeatures.FINANCIAL_WELLNESS]: !org ? true : !org?.isTcrOnly,
          [PlatformFeatures.TCR]: isEdit ? isTCRActive : true,
          firstName: '',
          lastName: '',
          email: '',
        } as ClientOrganizationInput),
      [org, isTCRActive, isEdit],
    );

    const handleEditOrganization = async (formValues: ClientOrganizationInput) => {
      const payload = {
        id: formValues.id,
        name: formValues.name,
        status: formValues.status ?? OrganizationStatus.Acitve,
        managerId: formValues.managerId,
        advisorId: formValues.advisorId,
        isTcrOnly: !formValues[PlatformFeatures.FINANCIAL_WELLNESS],
        isTcrActive: formValues[PlatformFeatures.TCR],
      };

      return updateOrganization(payload);
    };

    /*
      * Determine the type of update for the organization
      * @param formValues - The form values
      * @returns The type of update

      * The update types are:
      * - UPDATE: The organization is being updated - no confirmation modal is needed
      * - UPGRADE: The organization is being upgraded to include financial wellness features
      * - DOWNGRADE: The organization is being downgraded to remove financial wellness features
    */

    const getUpdateType = (formValues: ClientOrganizationInput) => {
      if (!org?.isTcrOnly === formValues[PlatformFeatures.FINANCIAL_WELLNESS]) {
        return OrganizationUpdateEnum.UPDATE;
      }

      if (org?.isTcrOnly && formValues[PlatformFeatures.FINANCIAL_WELLNESS]) {
        return OrganizationUpdateEnum.UPGRADE;
      }

      return OrganizationUpdateEnum.DOWNGRADE;
    };

    const validationSchema = Yup.object().shape({
      name: Yup.string().required('Organization Name is required.'),
      email: Yup.string()
        .email('Please provide a valid email address.')
        .when([], (_, schema) =>
          createOrganizationStep !== CreateOrganizationSteps.ADMIN_DETAILS
            ? schema
            : schema.required('Email is required.'),
        ),
      firstName: Yup.string().when([], (_, schema) =>
        createOrganizationStep !== CreateOrganizationSteps.ADMIN_DETAILS
          ? schema
          : schema.required('First name is required.'),
      ),
      lastName: Yup.string().when([], (_, schema) =>
        createOrganizationStep !== CreateOrganizationSteps.ADMIN_DETAILS
          ? schema
          : schema.required('Last name is required.'),
      ),
      managerId: Yup.string().required('Manager is required.'),
      advisorId: Yup.string().required('Advisor is required.'),
    });

    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        let result;

        const upgradeFlow = getUpdateType(values);
        setModalUpdateType(upgradeFlow);

        if (isEdit && upgradeFlow === OrganizationUpdateEnum.UPDATE) {
          result = await handleEditOrganization(values);
        } else if (isEdit && upgradeFlow !== OrganizationUpdateEnum.UPDATE) {
          setIsConfirmationModalOpen(true);
        } else if (createOrganizationStep === CreateOrganizationSteps.ORGANIZATION_DETAILS) {
          setCreateOrganizationStep(CreateOrganizationSteps.ADMIN_DETAILS);
        } else {
          const payload = {
            name: values.name,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            managerId: values.managerId,
            advisorId: values.advisorId,
            isTcrOnly: !values[PlatformFeatures.FINANCIAL_WELLNESS],
            isTcrActive: values[PlatformFeatures.TCR],
          };
          result = await createClientOrg(payload);
        }

        if (result) {
          await getCurrentSession({ superAdmin: [ALL_ORGANIZATIONS_QUERY] });
          await getOrgIdsByFeatureFlagConfig();
          onClose();
        }
      },
      enableReinitialize: true,
    });

    const customHandleFormikChange = (e: SelectChangeEvent<string | null>) => {
      if (e.target.name === 'advisorId') {
        if (advisors?.find((advisor) => advisor.id === e.target.value)?.bookingUrl) {
          formik.setFieldValue('advisorId', e.target.value);
        }
      }
    };

    // Sort the advisors by whether they have a booking URL
    const sortedAdvisors = useMemo(() => {
      if (!advisors) return [];
      return sortByProperty(advisors, 'bookingUrl') as Advisor[];
    }, [advisors]);

    useEffect(() => {
      if (open) {
        setCreateOrganizationStep(CreateOrganizationSteps.ORGANIZATION_DETAILS);
      }
    }, [open]);

    return (
      <CustomDrawer
        open={open}
        onClose={onClose}
        headerLabel={isEdit ? 'Edit Organization' : 'Add Organization'}
        headerIcon={isEdit ? <BorderColorOutlined /> : <AddIcon />}
      >
        <form style={sx.formWrapper} onSubmit={formik.handleSubmit}>
          <div style={sx.formContainer}>
            {createOrganizationStep === CreateOrganizationSteps.ORGANIZATION_DETAILS ? (
              <>
                {isEdit ? (
                  <>
                    <Typography variant="h6">{org?.name}</Typography>
                    <Typography sx={sx.headerOrgId} variant="subtitle1">
                      {org?.id}
                    </Typography>
                    <DashedDivider style={sx.dashedDivider} />
                  </>
                ) : (
                  <Typography variant="subtitle1">
                    Please provide the organization information
                  </Typography>
                )}
                <Typography sx={sx.section} variant="subtitle2">
                  Organization Information
                </Typography>
                <TextField
                  style={sx.formItems}
                  fullWidth
                  label="Organization Name"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  variant="outlined"
                  InputProps={{ style: sx.inputBorder }}
                  error={!!formik.errors.name}
                  helperText={formik.errors.name}
                />
                {isEdit && (
                  <FormControl fullWidth>
                    <InputLabel sx={sx.formItems}>Organization Status</InputLabel>
                    <Select
                      sx={sx.formItems}
                      fullWidth
                      name="status"
                      required
                      onChange={formik.handleChange}
                      value={formik.values.status}
                      label="Organization Status"
                    >
                      {organizationStatusOptions.map((status) => (
                        <MenuItem key={status.value} value={status.value}>
                          {status.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                <Typography sx={sx.section} variant="subtitle2">
                  Platform Features
                </Typography>
                <FormControlLabel
                  sx={sx.checkboxLabel}
                  control={
                    <Checkbox
                      name={`${PlatformFeatures.FINANCIAL_WELLNESS}`}
                      checked={formik.values[PlatformFeatures.FINANCIAL_WELLNESS]}
                      onChange={formik.handleChange}
                      sx={sx.checkbox}
                    />
                  }
                  label={platformFeaturesLabels.financialWellness}
                />
                <FormControlLabel
                  sx={sx.checkboxLabel}
                  control={
                    <Checkbox
                      name={`${PlatformFeatures.TCR}`}
                      checked={formik.values[PlatformFeatures.TCR]}
                      onChange={formik.handleChange}
                      sx={sx.checkbox}
                    />
                  }
                  label={platformFeaturesLabels.tcr}
                />
                <Typography sx={sx.section} variant="subtitle2">
                  Organization Management
                </Typography>
                <FormControl fullWidth>
                  <InputLabel error={!!formik.errors.managerId} shrink sx={sx.formItems}>
                    Manager
                  </InputLabel>
                  <Select
                    sx={sx.formItems}
                    fullWidth
                    name="managerId"
                    displayEmpty
                    onChange={formik.handleChange}
                    value={formik.values.managerId}
                    input={<OutlinedInput notched label="Manager" />}
                    error={!!formik.errors.managerId}
                  >
                    <MenuItem value="" disabled>
                      Select an Account Manager
                    </MenuItem>
                    {managers.map((manager) => (
                      <MenuItem key={manager.id} value={manager.id}>{`${manager.name}`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel error={!!formik.errors.advisorId} shrink sx={sx.formItems}>
                    Advisor
                  </InputLabel>
                  <Select
                    sx={sx.formItems}
                    fullWidth
                    displayEmpty
                    name="advisorId"
                    onChange={(e) => customHandleFormikChange(e)}
                    value={formik.values.advisorId}
                    input={<OutlinedInput notched label="Advisor" />}
                    error={!!formik.errors.advisorId}
                  >
                    <MenuItem value="" disabled>
                      Select an Advisor
                    </MenuItem>
                    {sortedAdvisors?.map((advisor) => {
                      // Determine if the advisor is disabled
                      const isDisabled = !advisor.bookingUrl;

                      // Tooltip title
                      const tooltipTitle = isDisabled
                        ? "This advisor cannot be assigned because they don't have a booking URL."
                        : '';

                      return (
                        <MenuItem
                          key={advisor.id}
                          sx={isDisabled ? sx.disabledMenuItem : {}}
                          value={advisor.id}
                          disabled={isDisabled}
                        >
                          <Tooltip key={advisor.id} title={tooltipTitle}>
                            <span
                              style={sx.fullsizeSpan}
                            >{`${advisor.firstName} ${advisor.lastName}`}</span>
                          </Tooltip>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </>
            ) : (
              <>
                <Typography variant="subtitle1">
                  Please provide the organization admin information
                </Typography>
                <Typography sx={sx.section} variant="subtitle2">
                  Organization Administrator
                </Typography>
                <TextField
                  style={sx.formItems}
                  fullWidth
                  label="First Name"
                  name="firstName"
                  onChange={formik.handleChange}
                  value={formik.values.firstName}
                  variant="outlined"
                  InputProps={{ style: sx.inputBorder }}
                  error={!!formik.errors.firstName}
                  helperText={formik.errors.firstName}
                />
                <TextField
                  style={sx.formItems}
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  onChange={formik.handleChange}
                  value={formik.values.lastName}
                  variant="outlined"
                  InputProps={{ style: sx.inputBorder }}
                  error={!!formik.errors.lastName}
                  helperText={formik.errors.lastName}
                />
                <TextField
                  style={sx.formItems}
                  fullWidth
                  label="Work Email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  variant="outlined"
                  InputProps={{ style: sx.inputBorder }}
                  error={!!formik.errors.email}
                  helperText={formik.errors.email}
                />
              </>
            )}
          </div>
          <div style={sx.button}>
            <CustomButton
              variant="default"
              type="submit"
              isLoading={formik.isSubmitting}
              disabled={
                !formik.values[PlatformFeatures.TCR] &&
                !formik.values[PlatformFeatures.FINANCIAL_WELLNESS]
              }
            >
              {isEdit ? 'Save Changes' : 'Add Organization'}
            </CustomButton>
          </div>
        </form>
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          onClose={() => setIsConfirmationModalOpen(false)}
          variant={modalUpdateType === OrganizationUpdateEnum.UPGRADE ? 'default' : 'destructive'}
          title={
            modalUpdateType === OrganizationUpdateEnum.UPGRADE
              ? `Are you sure you want to add Financial Wellness features to ${org?.name} at ElektraFi?`
              : 'Downgrade Organization'
          }
          confirmationText={org?.name || ''}
          icon={
            modalUpdateType === OrganizationUpdateEnum.UPGRADE ? (
              <AccountBalanceWalletOutlinedIcon />
            ) : (
              <DeleteOutlined />
            )
          }
          confirmationButtonLabel={
            modalUpdateType === OrganizationUpdateEnum.UPGRADE
              ? 'Confirm'
              : 'Remove Financial Wellness'
          }
          onConfirm={async () => {
            const result = await handleEditOrganization(formik.values);
            if (result) {
              await getCurrentSession({ superAdmin: [ALL_ORGANIZATIONS_QUERY] });
              setIsConfirmationModalOpen(false);
              onClose();
            }
          }}
        >
          {modalUpdateType === OrganizationUpdateEnum.UPGRADE ? (
            <>
              <p>
                To proceed with this change that affects all clients at this organization, please
                type the organization name:
              </p>
              <strong>{org?.name}</strong>
            </>
          ) : (
            <>
              <p>
                By removing the financial wellness features{' '}
                <strong>
                  will permanently delete clients financial data stored on the platform.
                </strong>
              </p>
              <p>To proceed with the deletion, please type the organization name:</p>
              <strong>{org?.name}</strong>
            </>
          )}
        </ConfirmationModal>
      </CustomDrawer>
    );
  },
);
