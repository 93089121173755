/* eslint-disable react/react-in-jsx-scope */
import { ALL_ADVISORS_QUERY, ALL_ORGANIZATIONS_QUERY, ClientOrganization } from '@graphql';
import { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import {
  DataGrid,
  GridActionsCellItem,
  GridColumns,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import { Button, TextField } from '@mui/material';
import {
  Delete as DeleteIcon,
  DeleteOutlined,
  Edit as EditIcon,
  FileDownload as FileDownloadIcon,
} from '@mui/icons-material';
import { appContext, paths } from '@utils';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { ConfirmationModal } from '@components';
import SearchIcon from '@mui/icons-material/Search';
import { AssignManager, OrganizationDrawer } from '..';
import { ExportUsers } from '../ExportUsers';

function CustomToolbar({ searchQuery, setSearchQuery }: any) {
  const [open, setOpen] = useState<boolean>(false);
  const {
    userStore: { user },
  } = useContext(appContext);

  const handleClose = () => setOpen(!open);

  return (
    <GridToolbarContainer style={{ justifyContent: 'space-between' }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </div>
      <div style={{ display: `flex`, alignItems: `center` }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          autoFocus
          style={{ marginRight: 16, width: `23rem` }}
          InputProps={{
            endAdornment: <SearchIcon />,
          }}
        />
        <Button variant="contained" onClick={handleClose}>
          Create Organization
        </Button>
      </div>
      <OrganizationDrawer open={open} onClose={handleClose} advisors={user.allAdvisors || []} />
    </GridToolbarContainer>
  );
}

export const AllOrganizationsTable = observer(() => {
  const [openManagerModal, setOpenManagerModal] = useState<boolean>(false);
  const [org, setOrg] = useState<ClientOrganization | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openExportUsersModal, setOpenExportUsersModal] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState('');

  const {
    userStore: { allOrganizations, getCurrentSession, sessionResponse, user },
    managersStore: { fetchAllManagers, resetManagers },
    organizationStore: { deleteOrganization, getOrgIdsByFeatureFlagConfig },
    historyStore: { history },
  } = useContext(appContext);

  useEffect(() => {
    getCurrentSession({ superAdmin: [ALL_ORGANIZATIONS_QUERY, ALL_ADVISORS_QUERY] });
  }, [getCurrentSession]);

  useEffect(() => {
    fetchAllManagers();
    return () => resetManagers();
  }, [fetchAllManagers, resetManagers]);

  useEffect(() => {
    getOrgIdsByFeatureFlagConfig();
  }, [getOrgIdsByFeatureFlagConfig]);

  const handleManagerModal = useCallback(() => {
    setOpenManagerModal(!openManagerModal);
  }, [openManagerModal]);

  const handleDeleteModal = useCallback(() => {
    setOpenDeleteModal(!openDeleteModal);
  }, [openDeleteModal]);

  const handleEditModal = useCallback(() => {
    setOpenEditModal(!openEditModal);
  }, [openEditModal]);

  const handleExportUsersModal = useCallback(() => {
    setOpenExportUsersModal(!openExportUsersModal);
  }, [openExportUsersModal]);

  const handleDeleteOrganization = useCallback(async () => {
    if (!org) return;

    const result = await deleteOrganization({ id: org?.id });
    if (result) {
      await getCurrentSession({ superAdmin: [ALL_ORGANIZATIONS_QUERY] });
      handleDeleteModal();
    }
  }, [org, deleteOrganization, getCurrentSession, handleDeleteModal]);

  const filteredOrganizations = useMemo(
    () =>
      allOrganizations.filter((organization) =>
        ['name', 'admin.name', 'advisor.firstName', 'advisor.lastName', 'status'].some((field) =>
          String(
            field
              .split('.')
              .reduce(
                (acc, key) => (acc && acc[key as keyof typeof acc]) || '',
                organization as any,
              ),
          )
            .toLowerCase()
            .includes(searchQuery.toLowerCase()),
        ),
      ),
    [allOrganizations, searchQuery],
  );

  const columns = useMemo<GridColumns>(
    () => [
      { field: 'id', headerName: 'ID', disableColumnMenu: true, flex: 1 },
      { field: 'name', headerName: 'Name', disableColumnMenu: true, flex: 1 },
      {
        field: 'admin',
        headerName: 'Account Manager',
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (params) => params.row.admin.name,
      },
      {
        field: 'advisor',
        headerName: 'Advisor',
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (params) => `${params.row.advisor?.firstName} ${params.row.advisor?.lastName}`,
      },
      { field: 'status', headerName: 'Status', disableColumnMenu: true, flex: 1 },
      {
        field: 'numberOfUsers',
        headerName: 'Clients',
        disableColumnMenu: true,
        flex: 1,
        valueGetter: (params) => params.row.allClients.length,
      },
      {
        field: 'actions',
        type: 'actions',
        width: 60,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit Organization"
            onClick={() => {
              setOrg(params.row);
              handleEditModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<FileDownloadIcon />}
            label="Export Users"
            onClick={() => {
              setOrg(params.row);
              handleExportUsersModal();
            }}
            showInMenu
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete Organization"
            onClick={() => {
              setOrg(params.row);
              handleDeleteModal();
            }}
            showInMenu
          />,
        ],
      },
      {
        field: 'arrowForward',
        headerName: '',
        align: 'center',
        renderCell: () => <ArrowForwardIosOutlinedIcon />,
      },
    ],
    [handleEditModal, handleDeleteModal, handleExportUsersModal],
  );

  return (
    <div style={{ height: 'inherit', width: '100%' }}>
      <DataGrid
        rows={filteredOrganizations.map((organization) => ({
          ...organization,
          id: organization.id,
          admin: organization.admin || { name: 'No Manager' },
          advisor: organization.advisor || { firstName: 'No', lastName: 'Advisor' },
        }))}
        columns={columns}
        sx={{ '& .MuiDataGrid-row': { cursor: 'pointer' } }}
        isRowSelectable={() => false}
        loading={sessionResponse.status === 'pending'}
        onCellClick={(params) => {
          if (params?.field === 'id') {
            toast('Organization ID was copied!', { type: 'success' });
            return navigator.clipboard.writeText(params.row.id);
          }
          if (params?.field === 'actions') return null;
          return history.push(`${paths.organizations}/${params.id}`);
        }}
        components={{
          Toolbar: () => (
            <CustomToolbar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          ),
        }}
      />
      {openManagerModal && <AssignManager handleClose={handleManagerModal} org={org as any} />}
      <OrganizationDrawer
        open={openEditModal}
        onClose={handleEditModal}
        org={org as any}
        isEdit
        advisors={user.allAdvisors || []}
      />
      <ConfirmationModal
        icon={<DeleteOutlined />}
        title={`Are you sure you want to delete ${org?.name} from ElektraFi?`}
        onClose={handleDeleteModal}
        isOpen={openDeleteModal && org !== null}
        variant="destructive"
        confirmationText={org?.name || ''}
        onConfirm={handleDeleteOrganization}
        confirmationButtonLabel="Delete Organization"
      >
        <p>
          Deleting this organization{' '}
          <strong>
            will permanently remove access from all clients to ElektraFi and delete their financial
            data stored on the platform.
          </strong>
        </p>
        <p>To proceed with the deletion, please type the organization name: </p>
        <strong>{org?.name}</strong>
      </ConfirmationModal>
      {openExportUsersModal && (
        <ExportUsers handleClose={handleExportUsersModal} org={org as any} />
      )}
    </div>
  );
});
