import React, { useContext } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { useFormik } from 'formik';
import { Settings, SETTINGS_FRAGMENT, SETTINGS_QUERY } from '@graphql';
import { BorderColorOutlined } from '@mui/icons-material';
import { CustomButton, CustomDrawer } from '@components';
import { appContext } from '@utils';
import MoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import { globalSettingsStyles } from '../../utils';

interface ITaxOverviewDrawerProps {
  handleClose: () => void;
  open: boolean;
  settings: Settings;
}

export function TaxOverviewDrawer({ open, handleClose, settings }: ITaxOverviewDrawerProps) {
  const sx = globalSettingsStyles();

  const {
    userStore: { getCurrentSession },
    settingsStore: { updateSettings },
  } = useContext(appContext);

  const formik = useFormik({
    initialValues: settings,
    onSubmit: async (values) => {
      if (!settings) return;
      const result = await updateSettings(values);

      if (result) {
        await getCurrentSession({ superAdmin: [SETTINGS_QUERY] }, [SETTINGS_FRAGMENT]);
        handleClose();
      }
    },
    enableReinitialize: true,
  });

  return (
    <CustomDrawer
      open={open}
      onClose={handleClose}
      headerLabel="Edit Overview"
      headerIcon={<BorderColorOutlined />}
    >
      <form style={sx.formWrapper} onSubmit={formik.handleSubmit}>
        <div style={sx.formContainer}>
          <TextField
            sx={sx.formItems}
            fullWidth
            label="CPP Income Threshold"
            name="tax.cppIncomeThreshold"
            onChange={formik.handleChange}
            value={formik.values.tax?.cppIncomeThreshold || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start" sx={sx.inputIcon}>
                  <MoneyIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="CPP Rate"
            name="tax.cppCredit.contributionRate"
            onChange={formik.handleChange}
            value={formik.values.tax?.cppCredit?.contributionRate || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start" sx={sx.inputIcon}>
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={sx.formItems}
            fullWidth
            label="CPP Threshold"
            name="tax.cppCredit.threshold"
            onChange={formik.handleChange}
            value={formik.values.tax?.cppCredit?.threshold || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start" sx={sx.inputIcon}>
                  <MoneyIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="EI Rate"
            name="tax.eiCredit.contributionRate"
            onChange={formik.handleChange}
            value={formik.values.tax?.eiCredit?.contributionRate || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start" sx={sx.inputIcon}>
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="EI Threshold"
            name="tax.eiCredit.threshold"
            onChange={formik.handleChange}
            value={formik.values.tax?.eiCredit?.threshold || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start" sx={sx.inputIcon}>
                  <MoneyIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Personal Credit"
            name="tax.personalCredit"
            onChange={formik.handleChange}
            value={formik.values.tax?.personalCredit || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start" sx={sx.inputIcon}>
                  <MoneyIcon />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            sx={sx.formItems}
            fullWidth
            label="Refundable Tax Claim"
            name="tax.refundableTaxClaim"
            onChange={formik.handleChange}
            value={formik.values.tax?.refundableTaxClaim || ''}
            variant="outlined"
            type="number"
            required
            InputProps={{
              sx: sx.inputBorder,
              startAdornment: (
                <InputAdornment position="start" sx={sx.inputIcon}>
                  <PercentIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div style={sx.button}>
          <CustomButton variant="default" type="submit" isLoading={formik.isSubmitting}>
            Save Changes
          </CustomButton>
        </div>
      </form>
    </CustomDrawer>
  );
}
