/* eslint-disable consistent-return */
import {
  ADD_BULK_CLIENT_TO_ORG_MUTATION,
  ADD_CLIENT_TO_ORG_MUTATION,
  ALL_PENDING_USERS_BY_ORG_REQUESTS_QUERY,
  CREATE_CLIENT_ORG_MUTATION,
  ClientInput,
  ClientOrganizationInput,
  DELETE_CLIENTS_FROM_ORG_MUTATION,
  DELETE_ORGANIZATION_MUTATION,
  DeleteClientInput,
  DeleteOrganizationInput,
  OrgIdsByFeatureFlagConfig,
  GET_ORG_IDS_BY_FEATURE_FLAGS_CONFIG,
  INVITE_CLIENT,
  UPDATE_CLIENT_DETAIL_MUTATION,
  UPDATE_CLIENT_EMAIL_MUTATION,
  UPDATE_CLIENT_ORGANIZATION_MUTATION,
  UpdateClientInput,
  UpdateClientOrganizationInput,
} from '@graphql';
import { store } from '@store';
import { graphqlApi } from '@utils';
import { flow, makeAutoObservable } from 'mobx';
import { toast } from 'react-toastify';
import { DEFAULT_HTTP_RESPONSE } from './defaults';

export class OrganizationsStore {
  orgIdsByFeatureFlagConfig: OrgIdsByFeatureFlagConfig[] = [];

  createClientOrgResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  addClientToOrgResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  deleteClientFromOrgResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  inviteClientFromOrgResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  addBulkClientToOrgResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  // assignAdvisorToClientResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  deleteOrganizationResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  updateOrganizationResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  updateClientDetailResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  updateClientEmailResponse: HttpResponse = DEFAULT_HTTP_RESPONSE;

  constructor() {
    makeAutoObservable(this);
  }

  createClientOrg = flow(function* createClientOrg(
    this: OrganizationsStore,
    variables: ClientOrganizationInput,
  ): any {
    try {
      if (variables.managerId === ' ') {
        toast('Please assign an account manager to the organization.', { type: 'error' });
        return;
      }

      if (variables.advisorId === ' ') {
        toast('Please assign an advisor to the organization.', { type: 'error' });
        return;
      }

      const manager = variables.managerId !== ' ' ? variables.managerId : null;
      const advisor = variables.advisorId !== ' ' ? variables.advisorId : null;

      this.createClientOrgResponse.status = 'pending';
      const userResponse = yield graphqlApi(CREATE_CLIENT_ORG_MUTATION, {
        ...variables,
        managerId: manager,
        advisorId: advisor,
      });
      this.createClientOrgResponse.status = 'ok';
      if (userResponse?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      const userInfo = userResponse?.data?.data?.createClientOrganization;
      if (userInfo) toast('Organization created.', { type: 'success' });
      else toast('Account already exist.', { type: 'info' });
      return userInfo;
    } catch (err) {
      toast('Something went wrong', { type: 'success' });
      this.createClientOrgResponse.status = 'badRequest';
      this.createClientOrgResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  addClientToOrg = flow(function* addClientToOrg(
    this: OrganizationsStore,
    variables: ClientInput,
  ): any {
    try {
      this.addClientToOrgResponse.status = 'pending';
      const userResponse = yield graphqlApi(ADD_CLIENT_TO_ORG_MUTATION, variables);
      this.addClientToOrgResponse.status = 'ok';
      if (userResponse?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      const userInfo = userResponse?.data?.data?.addClientToOrg;
      if (userInfo) toast('Client Added', { type: 'success' });
      else toast('Account already exist.', { type: 'info' });
      return userInfo;
    } catch (err) {
      toast('Something went wrong', { type: 'error' });
      this.addClientToOrgResponse.status = 'badRequest';
      this.addClientToOrgResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  deleteClientsFromOrg = flow(function* deleteClientFromOrg(
    this: OrganizationsStore,
    variables: DeleteClientInput,
  ): any {
    try {
      this.deleteClientFromOrgResponse.status = 'pending';
      const response = yield graphqlApi(DELETE_CLIENTS_FROM_ORG_MUTATION, variables);
      this.deleteClientFromOrgResponse.status = 'ok';
      const isClientDeleted = response?.data?.data?.deleteClientsFromOrg;
      if (isClientDeleted) {
        toast('Client deleted from organization', { type: 'success' });
      } else {
        toast('Client not deleted from the organization', { type: 'error' });
      }
      return isClientDeleted;
    } catch (err) {
      toast('Error deleting client from the organization', { type: 'error' });
      this.deleteClientFromOrgResponse.status = 'badRequest';
      this.deleteClientFromOrgResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  inviteClient = flow(function* inviteClientFromOrg(
    this: OrganizationsStore,
    variables: Array<string>,
  ): any {
    try {
      this.inviteClientFromOrgResponse.status = 'pending';
      const response = yield graphqlApi(INVITE_CLIENT, { input: variables });
      this.inviteClientFromOrgResponse.status = 'ok';
      const isClientInvited = response?.data?.data?.sendInviteEmails;
      if (isClientInvited) {
        toast('Client invitation sent', { type: 'success' });
      } else {
        toast('Client invitation failed', { type: 'error' });
      }
      return isClientInvited;
    } catch (err) {
      toast('Error inviting client', { type: 'error' });
      this.inviteClientFromOrgResponse.status = 'badRequest';
      this.inviteClientFromOrgResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  addBulkClientToOrg = flow(function* addBulkClientToOrg(
    this: OrganizationsStore,
    variables: ClientInput[],
    id: string,
  ): any {
    try {
      this.addBulkClientToOrgResponse.status = 'pending';
      const userResponse = yield graphqlApi(ADD_BULK_CLIENT_TO_ORG_MUTATION, {
        input: variables,
        org_id: id,
      });
      this.addBulkClientToOrgResponse.status = 'ok';
      if (userResponse?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      if (userResponse?.data?.errors?.length > 0) {
        throw new Error(userResponse?.data?.errors[0]?.message);
      }
      const userInfo = userResponse?.data?.data?.bulkImportClients;
      if (userInfo) toast('User account(s) added', { type: 'success' });
      else toast('Account(s) already exist.', { type: 'info' });
      return userInfo;
    } catch (err) {
      toast('Something went wrong', { type: 'error' });
      this.addBulkClientToOrgResponse.status = 'badRequest';
      this.addBulkClientToOrgResponse.errMessage = 'Bad Request';
      return null;
    }
  }).bind(this);

  // assignAdvisorToClient = flow(function* assignAdvisorToClient(
  //   this: OrganizationsStore,
  //   variables: AssignAdvisorToClientInput,
  // ): any {
  //   try {
  //     this.assignAdvisorToClientResponse.status = 'pending';
  //     const userResponse = yield graphqlApi(ASSIGN_ADVISOR_TO_CLIENT_MUTATION, variables);
  //     this.assignAdvisorToClientResponse.status = 'ok';
  //     if (userResponse?.data?.errors?.[0]?.message === 'Unauthorized') {
  //       store.userStore.clearSession();
  //       return null;
  //     }
  //     const userInfo = userResponse?.data?.data?.assignAdvisorToClient;
  //     if (userInfo) toast('Advisor assigned to client', { type: 'success' });
  //     else toast('Advisor update failed', { type: 'info' });
  //     return userInfo;
  //   } catch (err) {
  //     toast('Something went wrong', { type: 'error' });
  //     this.assignAdvisorToClientResponse.status = 'badRequest';
  //     this.assignAdvisorToClientResponse.errMessage = 'Bad Request';
  //   }
  // }).bind(this);

  deleteOrganization = flow(function* deleteOrganization(
    this: OrganizationsStore,
    variables: DeleteOrganizationInput,
  ): any {
    try {
      this.deleteOrganizationResponse.status = 'pending';
      const response = yield graphqlApi(DELETE_ORGANIZATION_MUTATION, variables);
      this.deleteOrganizationResponse.status = 'ok';
      if (response?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      toast('Organization deleted', { type: 'success' });
      return response?.data?.data?.deleteOrganization;
    } catch (err) {
      toast('Something went wrong', { type: 'error' });
      this.deleteOrganizationResponse.status = 'badRequest';
      this.deleteOrganizationResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  updateOrganization = flow(function* updateOrganization(
    this: OrganizationsStore,
    variables: UpdateClientOrganizationInput,
  ): any {
    try {
      this.updateOrganizationResponse.status = 'pending';
      const response = yield graphqlApi(UPDATE_CLIENT_ORGANIZATION_MUTATION, variables);
      this.updateOrganizationResponse.status = 'ok';
      if (response?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      toast('Organization updated', { type: 'success' });
      return response?.data?.data?.updateClientOrganization;
    } catch (err) {
      toast('Something went wrong', { type: 'error' });
      this.updateOrganizationResponse.status = 'badRequest';
      this.updateOrganizationResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  updateClientDetail = flow(function* updateClientDetail(
    this: OrganizationsStore,
    variables: UpdateClientInput,
  ): any {
    try {
      this.updateClientDetailResponse.status = 'pending';
      const response = yield graphqlApi(UPDATE_CLIENT_DETAIL_MUTATION, variables);
      this.updateClientDetailResponse.status = 'ok';

      if (response?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }

      if (response?.data?.errors?.[0]?.message?.includes('duplicate key value violates unique')) {
        toast('User with this email already exists', { type: 'error' });
        this.updateClientDetailResponse.status = 'badRequest';
        this.updateClientDetailResponse.errMessage = 'Bad Request';

        return null;
      }

      toast('Client updated', { type: 'success' });

      return response?.data?.data?.updateClientDetail;
    } catch (err) {
      toast('Something went wrong', { type: 'error' });
      this.updateClientDetailResponse.status = 'badRequest';
      this.updateClientDetailResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  updateClientEmail = flow(function* updateClientDetail(
    this: OrganizationsStore,
    variables: { id: string; email: string },
  ): any {
    try {
      this.updateClientEmailResponse.status = 'pending';
      const response = yield graphqlApi(UPDATE_CLIENT_EMAIL_MUTATION, variables);
      this.updateClientDetailResponse.status = 'ok';
      if (response?.data?.errors?.[0]?.message === 'Unauthorized') {
        store.userStore.clearSession();
        return null;
      }
      if (response?.data.data.updateClientEmail.message === 'client_already_exists') {
        toast('Client already exists', { type: 'error' });
        this.updateClientEmailResponse.status = 'badRequest';
        this.updateClientEmailResponse.errMessage = 'Bad Request';
        return null;
      }
      if (response?.data?.data?.updateClientEmail?.message === 'firebase_error') {
        toast('Something went wrong', { type: 'error' });
        this.updateClientEmailResponse.status = 'badRequest';
        this.updateClientEmailResponse.errMessage = 'Bad Request';
        return null;
      }
      toast('Client updated', { type: 'success' });
      return response?.data?.data?.updateClientEmail;
    } catch (err) {
      toast('Something went wrong', { type: 'error' });
      this.updateClientEmailResponse.status = 'badRequest';
      this.updateClientEmailResponse.errMessage = 'Bad Request';
    }
  }).bind(this);

  getAllPendingUsersByOrg = flow(function* getAllPendingUsersByOrg(
    this: OrganizationsStore,
    variables: { orgId: string },
  ): any {
    try {
      const response = yield graphqlApi(ALL_PENDING_USERS_BY_ORG_REQUESTS_QUERY, variables);
      return response?.data?.data?.allPendingUsersByOrg;
    } catch (err) {
      toast('Something went wrong', { type: 'error' });
    }
  }).bind(this);

  getOrgIdsByFeatureFlagConfig = flow(function* getOrgIdsByFeatureFlagConfig(
    this: OrganizationsStore,
  ): any {
    try {
      const response = yield graphqlApi(GET_ORG_IDS_BY_FEATURE_FLAGS_CONFIG);
      this.orgIdsByFeatureFlagConfig = response?.data?.data?.getOrgIdsByFeatureFlagConfig;
      return response?.data?.data?.getOrgIdsByFeatureFlagConfig;
    } catch (err) {
      toast('Something went wrong', { type: 'error' });
    }
  }).bind(this);
}
